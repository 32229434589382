/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
  color: #333333;
}

/* Typing animation */
.typing-text {
  overflow: hidden;
  border-right: 2px solid #333;
  white-space: nowrap;
  margin: 0 auto;
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #333 }
}

/* Auth buttons */
.auth-button {
  padding: 12px 24px;
  margin: 10px;
  border: 2px solid #333;
  background: transparent;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-button:hover {
  background: #333;
  color: #fff;
}

/* Chat interface */
.chat-container {
  display: flex;
  height: 100vh;
}

.side-panel {
  width: 250px;
  border-right: 1px solid #eee;
  padding: 20px;
}

.main-chat {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  margin: 10px 0;
  padding: 15px;
  border-radius: 4px;
  max-width: 80%;
}

.user-message {
  background: #f5f5f5;
  align-self: flex-end;
}

.assistant-message {
  background: #fff;
  border: 1px solid #eee;
  align-self: flex-start;
}
